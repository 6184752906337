<template>
  <!-- 购物车删除确认框 -->
  <el-dialog title="CONFIRMATION" :visible.sync="confirmDialog" center class="confirmDialog" :fullscreen="screenWidth < 641">
    <span v-if="[1, 2, 5, 7, 9].includes(ticketItem.ticketKind)">When {{ getTicketTypeName(ticketItem.ticketKind) }} is removed from the
      cart, the shopping cart will be emptied.</span>
    <span v-else>When {{ ticketItem.officialName }} is removed from the cart,
      {{ extrasName }} will also be removed from the cart.</span>
    <p>Do you still want to remove it?</p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="confirmDialog = false">NO</el-button>
      <el-button type="primary" @click="confirm()">YES</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mixin2 } from "@/mixin/index";
import { judgeIphone } from '../utils/util'
export default {
  mixins: [mixin2],
  data() {
    return {
      confirmDialog: false,
      ticketItem: {},
      screenWidth: document.body.clientWidth,
      extrasName: ""
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    init(ticketitem, extrasName) {
      this.confirmDialog = true;
      this.ticketItem = ticketitem;
      this.extrasName = extrasName;
      setTimeout(() => {
        judgeIphone() ? document.querySelector('.el-dialog__body').style.height = '66vh' : ''
      }, 20);
    },
    confirm() {
      this.confirmDialog = false;
      this.$emit("confirm");
    },
    getTicketTypeName(value) {
      const options = {
        1: "1 Day Studio Pass",
        2: "2 Day Studio Pass",
        5: "1.5 Day Studio Pass",
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
      };
      return options[value];
    }
  }
};
</script>
<style lang="scss" scoped>
// 删除票时的确认弹框
::v-deep.confirmDialog {
  .el-dialog {
    border-radius: 16px 16px 12px 12px;
    width: 640px;
    @media (max-width: 641px) {
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }
  .el-dialog__header {
    padding: 20px 20px;
    background-color: #162b75;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    @media (max-width: 641px) {
      border-radius: 0;
    }
    span {
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    @media (max-width: 641px) {
      height: calc(100vh - 157px);
    }
    font-weight: 500;
    color: #333;
    border-bottom: 1px solid #f2f2f2;
  }
  .el-dialog__footer {
    padding: 20px 25px;
    .dialog-footer {
      display: flex;
    }
    button {
      width: 40%;
      line-height: 25px;
      border-radius: 10px;
      font-weight: 600;
      margin: 0;
      flex: 1;
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
::v-deep .confirmDialog {
  @media (max-width: 768px) {
    .el-dialog {
      border-radius: 0;
    }
    .el-dialog__header {
      border-radius: 0;
    }
  }
}
</style>
